import * as React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const FieldHelp = ({ children }) => {
    return <small className="c-field-help">{children}</small>
}

FieldHelp.propTypes = {
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FieldHelp.defaultProps = {
    children: 'Place help text here'
}

export default FieldHelp
